export const APP_STAGE_LOADING = 'APP_STAGE_LOADING'
export const APP_STAGE_DISPLAY_COUPON = 'APP_STAGE_DISPLAY_COUPON'
export const APP_STAGE_NOT_FOUND = 'APP_STAGE_NOT_FOUND'

export const COUPON_INIT_DATA = 'COUPON_INIT_DATA'
export const COUPON_GET_DATA = 'COUPON_GET_DATA'
export const COUPON_SET_DATA = 'COUPON_SET_DATA'
export const COUPON_REQUEST_SHOP_CODE = 'COUPON_REQUEST_SHOP_CODE'

export const CODE_REQUEST_CODE = 'CODE_REQUEST_CODE'
export const CODE_GET_DATA = 'CODE_GET_DATA'
export const CODE_SET_DATA = 'CODE_SET_DATA'
export const CODE_POST_MONDAY = 'CODE_POST_MONDAY'
export const CODE_SET_VALID = 'CODE_SET_VALID'
export const CODE_GET_VALID = 'CODE_GET_VALID'
export const CODE_SET_PIC = 'CODE_SET_PIC'

export const CODE_POST_REDEEM_CORE = 'CODE_POST_REDEEM_CORE'
export const CODE_POST_BOOKING_CORE = 'CODE_POST_BOOKING_CORE'

export const POPUP_STAGE_HIDDEN = 'POPUP_STAGE_HIDDEN'
export const POPUP_STAGE_LOADING = 'POPUP_STAGE_LOADING'
export const POPUP_STAGE_CONSENT = 'POPUP_STAGE_CONSENT'
export const POPUP_STAGE_ERROR = 'POPUP_STAGE_ERROR'
export const POPUP_STAGE_NOTCONSENT = 'POPUP_STAGE_NOTCONSENT'
export const POPUP_STAGE_SUCCESS = 'POPUP_STAGE_SUCCESS'
export const POPUP_STAGE_LOCK = 'POPUP_STAGE_LOCK'
