import React from 'react'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function LockAppModal(props) {
  return (
    <div>
      <div
        className={'modal fade ' + (props.display ? 'show' : '')}
        id="LockApp"
        tabIndex="999999"
        role="dialog"
        style={props.display ? { display: 'block' } : {}}
      >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content">
            หากพบปัญหากรุณาติดต่อไปยังเจ้าหน้าที่โดยตรง <br />
            <span>
              Line :{' '}
              <a href="https://line.me/R/ti/p/@silvervoyage" target="blank_">
                @silvervoyage
              </a>
              <br />
            </span>
            ขออภัยในความไม่สะดวกอย่างสูง
          </div>
        </div>
      </div>
    </div>
  )
}

export default LockAppModal
